<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Setting",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        api: "/setting",
      },
      title: "Setting Fee",
      items: [
        {
          text: "Setting",
        },
        {
          text: "Setting Fee",
          active: true,
        },
      ],
      model: {
        setting: {
          platform_fee: null,
        },
      },
      submitform: false,
      routeBase: "category",
      profile_picture: null,
      show: false,
    };
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      api.get(_.config.api).then((response) => {
        let _ = this;
        _.model = response.data.data;
        console.log(_.model);
      });
    },
    save() {
      let _ = this;
      let dataPost = {
        type: "setting",
        setting: {
          platform_fee: _.model.setting.platform_fee,
          admin_fee: _.model.setting.admin_fee,
          payout_fee: _.model.setting.payout_fee,
          suspend_days: _.model.setting.suspend_days,
        },
      };
      api
        .post(_.config.api, dataPost)
        .then(() => {
          _.$toast("Setting succesfully Saved", {
            icon: "fas fa-check-circle",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mt-4">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(save)"
                    class="form-horizontal"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider
                          name="Platform Fee"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Platform Fee (%)"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.setting.platform_fee"
                              type="number"
                              step="0.01"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Admin Fee"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Admin Fee (Rp)"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.setting.admin_fee"
                              type="number"
                              step="0.01"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Payout Fee"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Payout Fee (Rp)"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.setting.payout_fee"
                              type="number"
                              step="0.01"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-6">
                        <ValidationProvider
                          name="Suspend Days"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-group
                            id="input-group-2"
                            label="Suspend Day (Day)"
                            label-for="input-2"
                          >
                            <b-form-input
                              id="input-2"
                              v-model="model.setting.suspend_days"
                              type="number"
                              step="1"
                            ></b-form-input>
                            <p class="fs-6 text-danger">
                              {{ errors[0] }}
                            </p>
                          </b-form-group>
                        </ValidationProvider>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-3">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Simpan</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lanng="scss" scoped>
.form-control {
  border: 1.5px solid #ced4da !important;
}
</style>
